import React, { useState, useCallback, useEffect } from 'react';
import { signup } from '../../../infrastructure/providers/auth0/auth0-requests';
import { useNavigate } from 'react-router-dom';

const SignUpPage: React.FC = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validatePassword = useCallback((password: string, confirmPassword: string): string => {
    if (password.length < 8) {
      return 'Must be at least 8 characters.';
    }
    if (!/\d/.test(password)) {
      return 'Must contain at least one digit.';
    }
    if (!/[a-z]/.test(password)) {
      return 'Must contain at least one lowercase letter.';
    }
    if (!/[A-Z]/.test(password)) {
      return 'Must contain at least one uppercase letter.';
    }
    if (!/\W/.test(password)) {
      return 'Must contain at least one special character.';
    }
    if (password !== confirmPassword) {
      return 'Passwords do not match.';
    }
    return '';
  }, []);

  useEffect(() => {
    const error = validatePassword(password, confirmPassword);
    setPasswordError(error);
  }, [password, confirmPassword, validatePassword]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (!passwordError) {
      signup(email, password);
      navigate('/registration');
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <div className="flex-center full-screen bg-gray">
      <div className="signup-container">
        <img src="/assets/images/x.svg" alt="close tab" className="close-btn" />
        <h1 className="center-mobile">Sign Up to Helpful</h1>
        <p className="signin-link center-mobile">
          Already have an account? <a href="/login">Sign in</a>
        </p>
        <form onSubmit={handleSubmit}>
          <label className="flex-col">
            Full Name
            <input
              type="text"
              placeholder="Enter full name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </label>
          <label className="flex-col">
            Email
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <label className="flex-col">
            Password
            <input
              type="password"
              placeholder="Create a password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </label>
          <label className="flex-col">
            Confirm password
            <input
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
            />
          </label>
          {passwordError && (
            <p className={`text-sm password-hint ${isSubmitted ? 'error-text' : ''}`}>
              {passwordError}
            </p>
          )}
          <button type="submit" className="action-form-btn sign-up-btn">
            Create account
          </button>
          <div className="justify-right text-sm text-gray">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" className="need-help-link">Need help?</a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpPage;