import React from 'react';
import { Button } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
const SignInButton = () => {
  const navigate = useNavigate();
  const handleSignUpClick = () => {
    navigate('/login');
  };

  return (
    <Button
      onClick={handleSignUpClick}
      backgroundColor="#98F565"
      color="#1F2B41"
      width="30%"
      variation="link"
      whiteSpace="nowrap"
    >
      Sign In
    </Button>
  );
};

export default SignInButton;
