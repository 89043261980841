import React, { useState, useEffect } from 'react';
import DashboardSVG from './responsiveSVG/dashboardSVG';
import OpportunitySVG from './responsiveSVG/opportunitySVG';
import ProjectSVG from './responsiveSVG/projectSVG';
import ProfileSVG from './responsiveSVG/profileSVG';
import ToolSVG from './responsiveSVG/toolSVG';
import './dashboard.css';

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [projectTab, setProjectTab] = useState('H');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/assets/dummyData/dashboardData.json');
        console.log('Response status:', response.status);
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }
        const text = await response.text();
        console.log('Response text:', text);
        const jsonData = JSON.parse(text);
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const toggleMenu = () => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  if (!data) return <div>Loading...</div>;

  return (
    <div className={`helpful-updates`}>
      <header className={`${isMenuCollapsed ? 'collapsed' : ''}`}>
        <nav>
          <ul>
            <li>ABOUT</li>
            <li>VOLUNTEER</li>
            <li>PARTNERS</li>
            <li>RESEARCH</li>
            <li>EVENTS</li>
            <li>BLOG</li>
            <li>CONTACT</li>
          </ul>
        </nav>
        <button className={`menu-toggle ${isMenuCollapsed ? 'text-black' : 'text-white'}`} onClick={toggleMenu}>
          {isMenuCollapsed ? '☰' : '✕'}
        </button>
      </header>
      <div className="content">
      <div className="aside">
        <button 
          className="aside-tab"
          onMouseEnter={() => setHoveredButton('dashboard')}
          onMouseLeave={() => setHoveredButton(null)}
        >
          <DashboardSVG color={hoveredButton === 'dashboard' ? '#888' : '#2A3952'} />
          Dashboard
        </button>
        <button 
          className="aside-tab"
          onMouseEnter={() => setHoveredButton('projects')}
          onMouseLeave={() => setHoveredButton(null)}
        >
          <ProjectSVG color={hoveredButton === 'projects' ? '#888' : '#B0B0B0'} />
          Projects
        </button>
        <button 
          className="aside-tab"
          onMouseEnter={() => setHoveredButton('opportunities')}
          onMouseLeave={() => setHoveredButton(null)}
        >
          <OpportunitySVG color={hoveredButton === 'opportunities' ? '#888' : '#B0B0B0'} />
          Opportunities
        </button>
        <button 
          className="aside-tab"
          onMouseEnter={() => setHoveredButton('profile')}
          onMouseLeave={() => setHoveredButton(null)}
        >
          <ProfileSVG color={hoveredButton === 'profile' ? '#888' : '#B0B0B0'} />
          Profile
        </button>
        <button 
          className="aside-tab"
          onMouseEnter={() => setHoveredButton('tools')}
          onMouseLeave={() => setHoveredButton(null)}
        >
          <ToolSVG color={hoveredButton === 'tools' ? '#888' : '#B0B0B0'} />
          Tools
        </button>
      </div>
        <main>
          <h1 className="dashboard-title">Helpful Updates</h1>
          <div className="tabs-updates-container">

            {/* floating tabs to left */}
            <div className="tabs">
              {data.tabs.map(tab => (
                <button 
                onClick={() => setProjectTab(tab.id)} 
                key={tab.id} 
                className={`tab ${projectTab === tab.id ? 'selected' : ''}`}
                style={{ '--tab-color': tab.color }}
              >
                <div className="image-holder">
                  <img src={tab.src} alt={tab.name} />
                  {tab.notificationCount > 0 && (
                    <span className="notification">{tab.notificationCount}</span>
                  )}
                </div>
              </button>
              ))}
            </div>

            {/* 'All helpful updates' tab */}
            <div className="updates-container">
              <h1>{data.tabs.find(tab => tab.id === projectTab)?.name || 'All Helpful Updates'}</h1>
              <div key={projectTab} className="updates-skin">
                {data.updates
                  .filter(update => projectTab === 'H' || update.id === projectTab)
                  .map(update => (
                    <div key={update.id} className="update">
                      <div className="update-header">
                        <div>{update.title}</div>
                        <div>{update.author} • {update.date}</div>
                      </div>
                      <ul>
                        {update.notes.map((note, index) => (
                          <li key={index}>{note}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;