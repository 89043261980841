import { Flex, Image, Link, Text } from '@aws-amplify/ui-react';
import React from 'react';
import LogoutButton from '../components/LogOut';
import SignInButton from '../components/SignInButton';

import { Link as RouterLink } from 'react-router-dom';
import NavigationBar from '../components/NavigationBar';

const HomePageTemp = () => {
  return (
    <React.Fragment>
      <Flex direction="column" alignItems="center">
        <NavigationBar />
        <Flex direction="row" style={{ borderTop: '2px solid #ECE7E7' }}>
          <Image alt="helper" src="../image98.png" width="50%"></Image>
          <Flex
            width="100%"
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="1.5em" fontWeight={700}>
              Welcome to Helpful
            </Text>
            <Text fontSize="0.75em" fontWeight={600} color="#535458">
              New to Helpful?
              <Link
                as={RouterLink}
                to="/sign-up"
                color="#4EA539"
                textDecoration="underline"
                paddingLeft="10px"
              >
                Sign Up now
              </Link>
            </Text>
            <SignInButton />
          </Flex>
        </Flex>
      </Flex>

      <div>Mock Home Page</div>
      <LogoutButton />
    </React.Fragment>
  );
};

export default HomePageTemp;
