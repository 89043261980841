import { Routes, Route } from 'react-router-dom';
import ProtectedRoutes from './view/components/ProtectedRoutes';
import LoginPage from './view/pages/auth/LoginPage';
import SignUpPage from './view/pages/auth/SignUpPage';
import DashboardPage from './view/pages/dashboard/DashboardPage';
import HomePageTemp from './view/pages/HomePageTemp';
import Registration from './view/pages/registration/Registration';
import Index from './view/pages/registration/v2/Index';
import Dashboard from './components/dashboard/dashboard';
import {PAGE_ROUTES} from "./configs/routes";

function App() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<HomePageTemp />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/dashboard" element={<Dashboard />} />
      {/* <Route path="/projects" element={<Projects />} /> */}

      <Route path={'/'+PAGE_ROUTES.DASHBOARD} element={<DashboardPage />} />
      <Route path="registration" element={<Registration />}>
        <Route index element={<Index />} />
      </Route>

      {/* Protected Routes */}
      <Route element={<ProtectedRoutes />}>
        {/* TODO :ENSURE DASHBOARD IS PROTECTED ONCE AUTH IS DONE */}
        {/* <Route path="/dashboard" element={<DashboardPage />} /> */}
      </Route>
    </Routes>
  );
}

export default App;
