const OpportunitySVG = ({ color }) => {
    return (
        <svg width="18" height="27" viewBox="0 0 18 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_524_237)">
                <path d="M11.4371 23.4389H5.89272C5.57978 23.4389 5.32617 23.1916 5.32617 22.8867C5.32617 22.5817 5.57978 22.3345 5.89272 22.3345H11.4371C11.75 22.3345 12.0036 22.5817 12.0036 22.8867C12.0036 23.1916 11.75 23.4389 11.4371 23.4389Z" fill={color}/>
                <path d="M6.35479 24C6.13338 24 5.96529 24.2428 6.00614 24.5053C6.22631 25.925 7.25822 27 8.5 27C9.74178 27 10.7734 25.925 10.9939 24.5053C11.0347 24.2428 10.8666 24 10.6452 24H6.35479Z" fill={color}/>
                <path d="M10.9084 21.8029H6.34095C5.54959 21.8029 4.88347 21.2151 4.82414 20.4648C4.63055 18.018 3.58003 16.4675 2.46741 14.8261C1.25452 13.0363 0 11.1852 0 8.19214C0 4.15038 3.04228 0.749169 7.23431 0.104664C8.2144 -0.0459954 9.24688 -0.0341273 10.2214 0.13862C14.2941 0.862246 17.25 4.24928 17.25 8.19214C17.25 11.1852 15.9955 13.0363 14.7819 14.8261C13.6693 16.4675 12.6184 18.018 12.4252 20.4648C12.3658 21.2151 11.6994 21.8029 10.9084 21.8029Z" fill={color}/>
                <path d="M5.09681 13.0577C4.92334 13.0577 4.75265 12.9815 4.64267 12.8384C1.52684 8.77129 3.82355 4.95436 3.92278 4.79381C4.07994 4.53997 4.42375 4.45524 4.69124 4.60425C4.95803 4.75327 5.04754 5.07964 4.89142 5.33316C4.80538 5.47392 2.87954 8.72447 5.55025 12.2104C5.73274 12.4484 5.67758 12.7821 5.42709 12.9555C5.32717 13.0244 5.21164 13.0577 5.09716 13.0577H5.09681Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_524_237">
                    <rect width="17.25" height="27" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default OpportunitySVG;